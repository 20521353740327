import React from "react"
import ArticleContentBody from "../../components/pages/ArticleDetails/ArticleContentBody"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const AccountantsFilesFolder = () => {
  return (
    <Layout>
      <Seo
        title="Accountants Files Folder"
        description={`Learn how to manage files shared with your accountant easily. Open the Accountant's Folder to view and download documents, and access files shared from your User’s Folder.`}
      />

      {/* This slug is matched with articles in Content */}
      <ArticleContentBody slug={`accountants-files-folder`} />
    </Layout>
  )
}

export default AccountantsFilesFolder
